<template>
  <div>
    <navbar-component></navbar-component>
    <main>
      <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center"
        style="min-height: 100vh;"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover "
          v-bind:style="{
            backgroundImage: 'url(' + require('../assets/img/bg.png') + ')',
          }"
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75"
          ></span>
        </div>

        <section class="pb-20 mt-24 ">
          <div class="container mx-auto px-4">
            <div class="flex flex-wrap">
              <div class="pt-6 w-full md:w-/12 px-4 text-center">
                <div
                  class="relative flex flex-col min-w-0 break-words w-full mb-8"
                >
                  <div class="px-4 py-5 flex-auto">
                    <h1 class="text-white text-5xl md:text-6xl">
                      Bigpot is
                    </h1>
                    <VueWriter
                      class="mt-10 text-3xl md:text-4xl"
                      :eraseSpeed="55"
                      :typeSpeed="75"
                      :delay="2000"
                      :array="[
                        'your good old ponzi style game.',
                        'a combination of all blockchain scheme.',
                        'A transparent blockchain lottery.',
                      ]"
                    />
                    <div class="my-10 md:my-20">

                    <p
                      class="py-5 text-md md:text-lg text-gray-300 font-bold"
                    >
                     BigPot is a brand new blockchain lottery. We have reformed the rules of a FOMO3D-like lottery game to be more transparent, robust, and fair. Our goal is to create a fun and entertaining game with the biggest prize pool in all of crypto history.
                    </p>
                    <p class="text-gray-400 font-bold md:px-20">
                      We will release our initial front end and smart contracts in early November. Afterwards, we plan on selling 100 NFTs (our NFT will grant you special privileges in our game), all funds raised will be put into prize pool, we will also be putting 100k of our own money to bootstrap our prize pool. After all our NFTs are sold we will start round 1 of our game likely in late November, exact timing will be announced closer to launch date. Details about token distribution will be announced in the future. 
                    </p>
                    </div>
                    <div
                      class="mt-10 mb-5 w-full flex break-words text-center items-center justify-center"
                    >
                      <p
                        class="text-sm md:text-lg text-white font-bold text-gray-300"
                      >
                        Build on
                      </p>
                      <img
                        class="w-24 md:w-40 px-3"
                        src="../assets/img/eth.png"
                        alt=""
                        srcset=""
                      />
                      <img
                        class="w-24 md:w-40 px-3"
                        src="../assets/img/bsc.png"
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>

                  <div>
                    <ul
                      class="flex flex-col lg:flex-row content-center items-center justify-center"
                    >
                      <li class="m-5">
                        <a
                          class="py-3 px-5 airdrop"
                          href="https://t.me/BigPotDealerBot"
                          target="_blank"
                          rel="noopener noreferrer"
                          >Get Airdrop Tokens</a
                        >
                      </li>
                      <li class="m-5">
                        <a
                          class="py-3 px-5 bg-white"
                          href="https://medium.com/@BigPotGame/intro-to-bigpot-1884cc3eec6a"
                          rel="noopener noreferrer"
                          >Intro to BigPot</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
    <!-- <footer-component></footer-component> -->
  </div>
</template>
<script>
import NavbarComponent from "../components/Navbar.vue";

// import FooterComponent from "../components/Footer.vue";
export default {
  name: "landing-page",
  components: {
    NavbarComponent,
    // FooterComponent
  },
};
</script>
<style scoped>
p {
  font-family: "cocogoose-ultralight", sans-serif;
}
h1,
a {
  font-family: "TV Patrol Gothic";
}
a.airdrop {
  background-color: #fbb12d;
}
a.airdrop:hover {
  background-color: #fba408;
}
</style>
