<template>
  <div id="app">
    <router-view />
  </div>
</template>
<style>
@font-face {
  font-family: "TV Patrol Gothic";
  font-style: normal;
  font-weight: normal;
  src: local("TV Patrol Gothic"), url("./assets/TV Patrol Gothic.ttf") format("truetype");
}
@font-face {
  font-family: "cocogoose-ultralight";
  font-style: normal;
  font-weight: normal;
  src: local("cocogoose-ultralight"), url("./assets/Cocogoose.ttf") format("truetype");
}
.is-typed {
  font-family: "TV Patrol Gothic"!important;
}
.is-typed .typed {
  color: #fbb12d!important;
}
.is-typed span.cursor {
  background-color: #fbb12d!important;
}
</style>